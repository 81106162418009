<template>
    <div>
        <span class="d-block d-xl-none">
            <div class="nav-item nav-search">
                <b-nav-item @click="showSearchBar = true" class="search-icon-mobile">
                    <feather-icon size="0.9x" style="color: white; margin-bottom: 2px; margin-left: 1px !important;" icon="SearchIcon" />
                </b-nav-item>
                <div class="search-input" :class="{'open': showSearchBar}">
                    <div class="search-input-icon">
                        <feather-icon icon="SearchIcon" />
                    </div>
                    <b-form-input class="search" v-model="searchQuery" @keyup="search" @keyup.enter="searchOnEnterPress" :placeholder="placeholderText"
                                  @blur="onFormBlur"
                                  @update="isSearchFocused = true"
                                  @click="isSearchFocused = true" />
                    <div class="search-input-close" @click="showSearchBar = false; searchQuery = ''">
                        <feather-icon icon="XIcon" />
                    </div>
                    <div class="content searchResultsDropdown">
                        <div class="search-input open p-0 m-0">
                            <vue-perfect-scrollbar :settings="perfectScrollbarSettings" :class="{'show': isSearchFocused && searchQuery }" class="search-list search-list-main scroll-area overflow-hidden" tagname="ul">
                                <ul>
                                    <li v-for="data in searchArray" :key="data.id" @click="onItemFromDropdownSelected(data)" class="suggestion-group-suggestion cursor-pointer">
                                        <span>
                                            <div v-if="searchIndex === 'web_shop'" class="p-0">{{ data.description_1 }}</div>
                                            <div v-if="searchIndex === 'all'" class="p-0 d-flex justify-content-between align-items-center"> 
                                                <div class="hit-title">
                                                    {{ data.title }}
                                                </div>
                                                <div class="hit-destination">
                                                    {{data.index}}
                                                </div>
                                            </div>
                                            <div v-else class="p-0">{{ data.title }}</div>
                                            <template v-if="!data.titleIncludesQuery && data.fieldSearchResult">
                                                <div class="suggestion-description"><small>{{ data.fieldSearchResult.name }}: <span v-html="data.fieldSearchResult.contentWithHTML"></span></small></div>
                                            </template>
                                        </span>
                                    </li>

                                    <li v-if="searchArray && !searchArray.length && searchQuery" class="suggestion-group-suggestion no-results">
                                        <span class="p-0">Ni rezultatov</span>
                                    </li>
                                </ul>
                            </vue-perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <span class="d-none d-xl-block px-2" style="width: 310px;">
            <div class="row p-0 search-rounded d-flex">
                <b-input-group>
                    <b-form-input class="search" v-model="searchQuery" @keyup="search" @keyup.enter="searchOnEnterPress" :placeholder="placeholderText"
                                  @blur="onFormBlur"
                                  @update="isSearchFocused = true"
                                  @click="isSearchFocused = true" />
                    <b-input-group-append>
                        <div class="pr-1 d-flex justify-content-end align-items-center serach-icon-home">
                            <feather-icon icon="SearchIcon" />
                        </div>
                    </b-input-group-append>
                </b-input-group>

                <!--                <div class="col-11 p-0">
                    <b-form-input class="search" v-model="searchQuery" @keyup="search" @keyup.enter="searchOnEnterPress" placeholder="ISKANJE"
                                  @blur="onFormBlur"
                                  @update="isSearchFocused = true"
                                  @click="isSearchFocused = true" />
                </div>

                <div class="col-1 p-0">
                    <div class="pr-1 d-flex justify-content-end align-items-center serach-icon-home">
                        <feather-icon icon="SearchIcon" />
                    </div>
                </div>-->
            </div>
            <div class="navbar-container content searchResultsDropdown">
                <div class="search-input open p-0 m-0">
                    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" :class="{'show': isSearchFocused && searchQuery }" class="search-list search-list-main scroll-area overflow-hidden" tagname="ul">
                        <ul>
                            <li v-for="data in searchArray" :key="data.id" @click="onItemFromDropdownSelected(data)" class="suggestion-group-suggestion cursor-pointer">
                                <span>
                                    <div v-if="searchIndex === 'web_shop'" class="p-0">{{ data.description_1 }}</div>
                                    <div v-if="searchIndex === 'all'" class="p-0 d-flex justify-content-between align-items-center"> 
                                        <div class="hit-title">
                                            {{ data.title }}
                                        </div>
                                        <div class="hit-destination pl-2">
                                            {{data.index}}
                                        </div>
                                    </div>
                                    <div v-else class="p-0">{{ data.title }}</div>
                                    <template v-if="!data.titleIncludesQuery && data.fieldSearchResult">
                                        <div class="suggestion-description"><small>{{ data.fieldSearchResult.name }}: <span v-html="data.fieldSearchResult.contentWithHTML"></span></small></div>
                                    </template>
                                </span>
                            </li>

                            <li v-if="searchQuery !== '' && searchArray.length === 0 && isSearchFocused" class="suggestion-group-suggestion no-results">
                                <span class="p-0">Ni rezultatov</span>
                            </li>
                        </ul>
                    </vue-perfect-scrollbar>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import {BNavItem, BFormInput, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
    export default {
        components: {
            VuePerfectScrollbar,
            BNavItem,
            BFormInput,
            BInputGroup,
            BInputGroupAppend
        },
        data() {
            return {
                perfectScrollbarSettings: {maxScrollbarLength: 30},
                searchQuery: '',
                searchArray: [],
                searchIndex: '',
                routeName: '',
                placeholderText: 'Išči',
                c2cSearchFields: [{name: 'content', translation: 'Opis'}, {name: 'town', translation: 'Mesto'}, {name: 'region', translation: 'Regija'}],
                realEstatesSearchFields: [{name: 'description', translation: 'Opis'}, {name: 'location', translation: 'Lokacija'}, {name: 'floor', translation: 'Etažnost'}],
                newsSearchFields: [{name: 'content', translation: 'Vsebina'}],
                webShopSearchFields: [{name: 'descrtiption_1', translation: 'Opis'}, {name: 'descrtiption_2', translation: 'Opis'}],
                servicesSearchFields: [{name: 'content', translation: 'Vsebina'}],
                allSearchFields: [{name: 'manufacturer_title', translation: 'Znamka'}, {name: 'content', translation: 'Vsebina'}],
                isSearchFocused: false,
                isItemBeingSelected: false,
                showSearchBar: false

            }
        },
        methods: {
            onFormBlur() {
                // when clicking away from SearchInput, Blur event fires
                // if it's clicking anywhere on page to close dropdown, that's OK
                // but if user is clicking on item from dropdown, isSearchFocused closes dropdown and
                // item from dropdown is not being clicked. Setting small timeout fixes that
                // Edge Chromium, Google Chrome - 100ms
                // Firefox - 150ms
                // Safari - just works
                setTimeout(() => {
                    if (!this.isItemBeingSelected) this.isSearchFocused = false
                }, 150)
            },
            search() {
                if (this.searchIndex) {
                    const thisIns = this

                    if (this.searchIndex === 'web_shop') {
                        const searchParam = this.searchQuery ? `search=${this.searchQuery}` : ''

                        thisIns.$http.get(`/api/user/v1/web_shop/?${searchParam}`)
                            .then(res => {
                                this.searchArray = res.data.items ?? []
                                this.findMatchingContent()
                            })
                            .catch(error => {
                                thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                            })
                    } else  {
                        const searchParam = this.searchQuery ? `search=${this.searchQuery}` : ''

                        thisIns.$http.get(`/api/user/v1/es/${this.searchIndex}?${searchParam}`)
                            .then(res => {
                                this.searchArray = res.data.items ?? []
                                this.findMatchingContent()
                            })
                            .catch(error => {
                                thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                            })
                    }
                }
            },
            searchOnEnterPress() {
                //this.$route.params.searchIndex = this.searchIndex

                if (this.searchIndex === 'web_shop') this.$router.push({'path': '/izdelki', 'query': {'search': this.searchQuery}})
                else this.$router.push({'path': this.$route.fullPath, 'query': {'search': this.searchQuery}})
                // this.searchQuery = ''
                this.searchArray = []
                this.showSearchBar = false

                if (this.$store.getters['app/currentBreakPoint'] !== 'xl') {
                    this.$store.commit('app/TOGGLE_OVERLAY', false)
                }
            },
            onItemFromDropdownSelected(item) {
                this.isItemBeingSelected = true
                if (this.routeName === 'ad-list' || this.routeName === 'ad') this.$router.push({ name: 'ad', params: {ad_id: item.id}})
                else if (this.routeName === 'real-estate-list' || this.routeName === 'real-estate') this.$router.push({name: 'real-estate', params: {real_estate_id: item.id, realEstateTitle: item.title}})
                else if (this.routeName === 'news-list' || this.routeName === 'news') this.$router.push({name: 'news', params: {news_id: item.id, is_index: false }})
                else if (this.routeName === 'web-shop-items-list' || this.routeName === 'web-shop-category-items-list' || this.routeName === 'web-shop-item' || this.routeName === 'shopping-card') this.$router.push({name: 'web-shop-item', params: {item_id: item.id}})
                else if (this.routeName === 'service-list' || this.routeName === 'service') this.$router.push({name: 'service', params: {service_id: item.id, is_index: false }})
                else if (item.index === 'Tržnica') {
                    this.$router.push({ name: 'ad', params: {ad_id: item.id}})
                } else if (item.index === 'Novice') {
                    this.$router.push({name: 'news', params: {news_id: item.id, is_index: false }})
                } else if (item.index === 'Trgovina') {
                    this.$router.push({name: 'web-shop-item', params: {item_id: item.id}})
                } else if (item.index === 'Storitve') {
                    this.$router.push({name: 'service', params: {service_id: item.id, is_index: false }})
                } else if (item.index === 'Nepremičnine') {
                    this.$router.push({name: 'real-estate', params: {real_estate_id: item.id, realEstateTitle: item.title}})
                }

                this.searchQuery = ''
                this.searchArray = []
                this.isItemBeingSelected = false
                this.showSearchBar = false
            },
            getRouteName() {
                this.routeName = this.$route.name
                if (this.routeName === 'ad-list' || this.routeName === 'ad') {
                    this.placeholderText = 'Išči (Tržnica)'
                    this.searchIndex = 'c2c'
                } else if (this.routeName === 'real-estate-list' || this.routeName === 'real-estate') {
                    this.placeholderText = 'Išči (Nepremičnine)'
                    this.searchIndex = 'real_estates'
                } else if (this.routeName === 'news-list' || this.routeName === 'news') {
                    this.placeholderText = 'Išči (Novice)'
                    this.searchIndex = 'news'
                } else if (this.routeName === 'web-shop-items-list' || this.routeName === 'web-shop-category-items-list' || this.routeName === 'web-shop-item' || this.routeName === 'shopping-card') {
                    this.placeholderText = 'Išči (Trgovina)'
                    this.searchIndex = 'web_shop'
                } else if (this.routeName === 'service-list' || this.routeName === 'service') {
                    this.placeholderText = 'Išči (Storitve)'
                    this.searchIndex = 'services'
                } else {
                    this.placeholderText = 'Išči'
                    this.searchIndex = 'all'
                }

                this.searchQuery = ''
                this.searchArray = []
                this.showSearchBar = false
            },
            findMatchingContent() {

                if (!this.searchQuery || !this.searchArray || !this.searchArray.length) return

                let searchFields = []
                if (this.searchIndex === 'c2c') searchFields = this.c2cSearchFields
                else if (this.searchIndex === 'real_estates') searchFields = this.realEstatesSearchFields
                else if (this.searchIndex === 'news') searchFields = this.newsSearchFields
                else if (this.searchIndex === 'web_shop') searchFields = this.webShopSearchFields
                else if (this.searchIndex === 'services') searchFields = this.servicesSearchFields
                else if (this.searchIndex === 'all') searchFields = this.allSearchFields
                // loop through results and check if query was found in title
                // otherwise check other fields

                for (const el of this.searchArray) {
                    el.titleIncludesQuery = el.title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    if (!el.titleIncludesQuery) this.findSearchQueryInFields(searchFields, el)
                }

            },
            findSearchQueryInFields(fieldsToSearch, ad) {

                for (const field of fieldsToSearch) {
                    if (!ad[field.name]) continue

                    // content & description usually contains HTML, strip it
                    let strippedHTMLStr = String(ad[field.name]).replace(/(<([^>]+)>)/gi, '').trim()

                    // find if field includes search query (search everything in lower case)
                    const idx = strippedHTMLStr.toLowerCase().indexOf(this.searchQuery.toLowerCase())
                    if (idx === -1) continue
    

                    // if string is too long to field in search results, we cut it
                    if (strippedHTMLStr.length > 100) {

                        // we want to cut string in a way, that searched query is gonna be visible somewhere in the middle
                        // (70 characters)__SEARCH QUERY__(30 characters)

                        // if string was found 70 characters or less in the string, we will cut from the start
                        const start = (idx - 70) >= 0 ? (idx - 70) : 0


                        // we will cut from index + 30 (if idx+40 is larger than string length, it will just cut to the end)
                        const end = idx + 30

                        strippedHTMLStr = strippedHTMLStr.substring(start, end)

                        // we append ... to the start, if it didn't cut from first character
                        // and we append .. to the end, if we cut some characters from the end
                        strippedHTMLStr = `${start === 0 ? '' : '...'}${strippedHTMLStr}${end > strippedHTMLStr.length ? '' : '...'}`
                    }


                    const textWithBoldedSearchQuery = strippedHTMLStr.toLowerCase().replace(this.searchQuery.toLowerCase(), `<b class="text-danger">${this.searchQuery.toUpperCase()}</b>`)

                    ad.fieldSearchResult = {
                        name: field.translation,
                        contentWithHTML: textWithBoldedSearchQuery
                    }

                    break

                }
            }
        },
        mounted() {
            this.getRouteName()
        },
        watch: {
            '$route.name'() {
                this.getRouteName()
            },
            'searchQuery'(val) {
                if (this.$store.getters['app/currentBreakPoint'] !== 'xl') {
                    this.$store.commit('app/TOGGLE_OVERLAY', Boolean(val))
                }
            }
        }
    }
</script>

<style scoped>
.hit-destination{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}
.hit-title{
    max-width: 80%;
    font-size: 13px;
    line-height: 23px;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.search-icon-mobile{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    background-color: #72a5d8;
    margin-top: 3px;
    margin-right: 5px;
}
.form-control, .input-group-text {
  border: none !important;
  background-color: #F3F3F3;
  color: black;
  font-weight: 400;
  font-size: 17px;
}
.search-rounded input{
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}
.serach-icon-home{
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    background: #F3F3F3;
}
input::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}
input:focus {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 3px 10px -3px rgba(0,0,0, 0.1) !important;
}
.form-control:focus + .input-group-append .serach-icon-home {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
}
.input-group {
  border-radius: 50px !important;
}
li:hover {
    background-color: rgba(114, 165, 216, 0.12) !important;
    color: #72a5d8 !important;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
.search-list {
  border-radius: 12px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0, 0.1) !important;
}
.search-list, li {
    background-color: white;
}
.suggestion-description {
    padding-top: 0.5em;
    padding-left: 0.8em;
}
</style>