<template>
    <div
        class="app-content content"
        :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass, { 'homepage': $route.path === '/' }, { 'otherSites': $route.path !== '/' }]"
    >
        <div class="content-overlay" />
        <div class="header-navbar-shadow" />
        <div
            class="content-wrapper"
            :class="contentWidth === 'boxed' ? 'container p-0' : null"
        >

            <div class="row mb-1">
                <div class="d-md-flex align-items-center d-none" v-bind:class="{ 'col-6': $route.path.includes('e-upravnik'), 'col-12': !$route.path.includes('e-upravnik') }">
                    <slot name="breadcrumb">
                        <app-breadcrumb />
                    </slot>
                </div>
               
                <div class="col-12 col-md-6 pl-md-3 p-0" v-if="$route.path.includes('e-upravnik')">
                    <div class="greeting-text text-center text-md-right pr-1 align-text-top">
                        {{ fullName }}, pozdravljen-a! 👋
                    </div>
                </div>

            </div>
           

            <div class="content-body">
                <transition
                    :name="routerTransition"
                    mode="out-in"
                >
                    <slot />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
    import useAppConfig from '@core/app-config/useAppConfig'

    export default {
        components: {
            AppBreadcrumb
        },
        computed: {
            fullName() {
                if (!this.$store.state.user.userData) return ''
                const data = this.$store.state.user.userData
                return data.name
            }
        },
        setup() {
            const { routerTransition, contentWidth } = useAppConfig()

            return {
                routerTransition, contentWidth
            }
        }
    }
</script>

<style>
    .otherSites{
        padding: 6.5rem 2rem 0 2rem !important;
    }
    .homepage{
        padding: 3.5rem 2rem 0 2rem !important;
    }
    .greeting-text{
            font-size: 2rem;
            font-weight: bold !important;
    }

    @media only screen and (max-width: 1199px) {
    .app-content.content{
            padding-top: 33px !important;
            padding-bottom: 0px !important ;
        }
    }
</style>
