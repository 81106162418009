<template>
    <b-row
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="content-header d-flex align-items-center"
    >
        <!-- Content Left -->
        <b-col
            class="content-header-left  d-flex align-items-center"
            cols="12"
            ref="breadcrumbs"
        >
            <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2 class="content-header-title float-left mb-0">
                        {{ $route.meta.pageTitle }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb >
                            <!-- v-if="!$route.path.includes('e-upravnik') || !$route.path.includes('e-upravnik')" -->
                            <b-breadcrumb-item to="/">
                                <div class="d-flex align-items-center"> 
                                    <img style="height: 15px; width:15px;" :src="require('@/assets/images/icons/home.svg')">
                                </div>
                            </b-breadcrumb-item>
                            <!-- <p class="d-none">{{ $route.meta.breadcrumb }}</p> -->
                            <b-breadcrumb-item
                                v-for="(item, index) in $route.meta.breadcrumb"
                                :key="'breadchrumb_'+ item.text + '_' + index"
                                :active="item.active"
                                :to="item.to"
                                :style="[item.active ? {'font-weight': 'bold'}: {}]"
                                style="color: #72a5d8 !important; z-index: 100;"
                            >
                                {{ item.text }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </b-col>
            </b-row>
        </b-col>

        <!-- Content Right -->
        <!-- <b-col
            class="content-header-right text-md-right d-md-block d-none mb-1"
            md="3"
            cols="12"
        > -->
        <!--            <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
            >

                <template #button-content>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                    >
                        <feather-icon icon="SettingsIcon" />
                    </b-button>
                </template>

            </b-dropdown>-->
        <!-- </b-col> -->
    </b-row>
</template>

<script>
    import {
        BBreadcrumb, BBreadcrumbItem, BRow, BCol
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        directives: {
            Ripple
        },
        components: {
            BBreadcrumb,
            BBreadcrumbItem,
            BRow,
            BCol
        },
        created() {
            // when manually adding breadcrumb (for example adding news title to breadcrumb)
            // Vue doesn't pick up the change from $router.meta.breadcrumb
            // forcing it to rerender, it picks up and displays
            // this.$forceUpdate()
            this.$root.$on('force-update-breadcrumbs', () => { 
                this.$forceUpdate()
            })

        }
    }
</script>

<style scoped>
    .breadcrumb .breadcrumb-item, .breadcrumb .router-link-active {
        color: #72a5d8 !important;
        font-size: 0.9rem;
    }
    .breadcrumb-item a {
        color: #72a5d8 !important;
        font-size: 0.9rem;
    }
    .breadcrumb-item a:hover {
        color: #72a5d8 !important;
        font-size: 0.9rem;
    }
</style>