<template>
    <div class="navbar-container d-flex content align-items-center nav-color">
        <div class="container px-0 px-sm-1">

            <b-navbar-nav class="d-none d-xl-flex nav navbar-nav">
                <template v-if="$store.state.user && $store.state.user.userData && $store.state.user.userData.administrator">
                    <!-- <b-nav-item>
                        <b-button class="account" pill @click="onGoToAdminPanel">Administratorski portal</b-button>
                    </b-nav-item> -->
                </template>
            </b-navbar-nav>

            <!-- Nav Menu Toggler -->
            <ul class="nav navbar-nav d-xl-none">
                <li class="nav-item">
                    <b-link
                        class="nav-link"
                        @click="toggleVerticalMenuActive"
                    >
                        <feather-icon
                            icon="MenuIcon"
                            size="21"
                        />
                    </b-link>
                </li>
            </ul>

            <!-- Left Col -->
            <!--        <div v-if="false" class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
        </div>
        <dark-Toggler class="d-none d-lg-block" />-->

            <b-navbar-nav class="nav navbar-nav">
                <search class="d-block d-xl-none"/>
                <b-nav-item @click="$router.push({name: 'shopping-card'})">
                    <img style="height: 17px; width:17px;" :src="require('@/assets/images/icons/shopping_bag.svg')">
                    <span class="items">{{ totalItemsInCart }}</span>
                </b-nav-item>
                <template v-if="!$store.state.user.loggedIn">
                    <b-nav-item>
                        <b-button class="account" pill @click="$router.push({ name: 'login' })">PRIJAVA</b-button>
                    </b-nav-item>
                    <b-nav-item>
                        <b-button class="account" pill  @click="$router.push({ name: 'register' })">REGISTRACIJA</b-button>
                    </b-nav-item>
                </template>
                <template v-else>
                    <div class="d-none d-xl-flex">
                        <b-nav-item @click="goToProfile">
                            <img style="height: 17px; width:17px;" :src="require('@/assets/images/icons/profile.svg')">
                        </b-nav-item>
                        <b-nav-item>
                            <!-- :disabled="!emailConfirmed" -->
                            <b-button class="account" pill @click="goToProfile">{{ fullName }}</b-button>
                        </b-nav-item>
                        <b-nav-item>
                            <b-button class="account" pill @click="logout">Odjava</b-button>
                        </b-nav-item>
                    </div>
                    <span class="d-block d-xl-none">
                        <b-nav-item-dropdown right>
                            <template #button-content>
                                <img style="height: 17px; width:17px;" :src="require('@/assets/images/icons/profile.svg')">
                            </template>

                            <b-dropdown-item @click="goToProfile">
                                <a class="account">{{ fullName }}</a>
                            </b-dropdown-item>
                            <!-- <b-dropdown-item @click="onGoToAdminPanel" v-if="$store.state.user && $store.state.user.userData && $store.state.user.userData.administrator">
                                <a class="account">Administratorski portal</a>
                            </b-dropdown-item> -->
                            <b-dropdown-item @click="logout">
                                <a class="account">Odjava</a>
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </span>
                </template>
            </b-navbar-nav>
        </div>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItem, BButton, BNavItemDropdown, BDropdownItem 
    } from 'bootstrap-vue'
    //import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import authService from '@/auth/authService'
    import Search from '../../views/Components/Search.vue'

    export default {
        components: {
            BLink,
            BNavbarNav,
            Search,
            BNavItem,
            BButton,
            BNavItemDropdown,
            BDropdownItem
            // BNavItemDropdown,
            // BDropdownItem

            // Navbar Components
            //DarkToggler
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        data() {
            return {
                userData: this.$store.state.user.userData
            }
        },
        computed: {
            totalItemsInCart() {
                return this.$store.getters['shoppingCart/totalItems']
            },
            fullName() {
                if (!this.$store.state.user.userData) return ''
                const data = this.$store.state.user.userData
                return `${data.name} ${data.surname}` 
            },
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        },
        methods: {
            onGoToAdminPanel() {
                window.location.href = '/admin/'
            },
            logout() {
                authService.logOut()
                window.location.href = '/prijava'
            },
            goToProfile() {
                this.$router.push('/my-profile/my-dashboard')
            }
        }
    }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
.nav-color {
  background-color: #eaf3f9;
  height: 35px !important;
}
.account {
  background-color: transparent !important;
  border-color: #72a5d8 !important;
  color: #72a5d8 !important;
  padding: 0.2rem 0.5rem; 
  font-weight: bold;
  font-size: 12px;
}
.account:hover{
    box-shadow: none !important;
}
.items {
  background-color: #72a5d8;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 15px;
  font-family: 'Secular One', sans-serif;
}
</style>
