<template>
    <b-modal class="sell-story" centered v-model="show" hide-header hide-footer no-close-on-backdrop>
        <div class="d-flex justify-content-center mt-1 mb-1">
            <div>
                <div class="content-subs">
                    Prijava na e-novice je bila uspešna. 
                </div>
                <div class="content-subs1">
                    Za odjavo od e-novic, nam pišite na <span style="color: #72a5d8">info@esoseska.si</span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-2">
            <b-button @click="close">Zapri</b-button>
        </div>
    </b-modal>
</template>

<script>
    import {BModal, BButton} from 'bootstrap-vue'
    export default {
        components: {BModal, BButton},
        data() {
            return {
                show: false
            }
        },
        methods: {
            open() {
                this.show = true
            },
            close() {
                this.show = false
            }
        }
    }
</script>

<style scoped>
    .content-subs{
        text-align: center;
        font-size: 25px;
        padding-bottom: 10px;
    }

     .content-subs1{
        text-align: center;
        font-size: 15px;
    }
</style>